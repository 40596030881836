/* Browser Config */
html, body, #root {
    width: 100%;
    height: 100%;
}

.login-page {
    width: 100%;
    height: 100%;
    background-color: #0d6efd;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 10px;
    padding: 30px;
    width: 400px;
}

.login-form-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}